
<template>
  <div class="app">
  <!-- 根据后台数据，自动生成nutcellgroup上课记录列表 -->
  <nut-cell-group v-for="(item, index) in notifylist" :key="index">
    <nut-cell :title=item.classname :sub-title=item.time></nut-cell>
    <nut-cell title="课程名称" :desc="item.name">
      <template #icon>
      <Star />
     </template>
    </nut-cell>
    <nut-cell title="上课地点" :desc="item.place">
      <template #icon>
      <Location />
     </template>
    </nut-cell>
    <nut-cell title="上课老师" :desc="item.teacher">
      <template #icon>
      <My />
     </template></nut-cell>
    <nut-cell title="消耗课时" :desc="item.xiaohao">
      <template #icon>
      <Checked />
     </template>
    </nut-cell>
    <nut-cell title="扣课订单" :desc="item.order">
      <template #icon>
      <Order />
     </template>
    </nut-cell>
    <!-- 上课学生 -->
    <nut-cell :title="item.student" >
      <template #icon>
      <My2 />
     </template>
    </nut-cell>
  </nut-cell-group>
</div>
</template>
<script>
import { My,Star,Location,Order,Checked,My2 } from '@nutui/icons-vue-taro';
export default {
  components: {
    My,Star,Location,Order,Checked,My2
  },
  data() {
    return {
      notifylist: [
        {
          classname: '高年级C++班',
          time: '2021年9月1日（星期日）15：00~16：00',
          name: '钢琴课',
          place: '星乐思教室',
          teacher: '张老师',
          xiaohao: '1课时',
          order: '20210901',
          student: '张三'
        },
        {
          classname: '高年级C++班',
          time: '2021年9月2日',
          name: '钢琴课',
          place: '星乐思教室',
          teacher: '张老师',
          xiaohao: '1课时',
          order: '20210902',
          student: '张三'
        },
        {
          classname: '高年级C++班',
          time: '2021年9月3日',
          name: '钢琴课',
          place: '星乐思教室',
          teacher: '张老师',
          xiaohao: '1课时',
          order: '20210903',
          student: '张三'
        },
        {
          classname: '高年级C++班',
          time: '2021年9月4日',
          name: '钢琴课',
          place: '星乐思教室',
          teacher: '张老师',
          xiaohao: '1课时',
          order: '20210904',
          student: '张三'
        },
        {
          classname: '高年级C++班',
          time: '2021年9月5日',
          name: '钢琴课',
          place: '星乐思教室',
          teacher: '张老师',
          xiaohao: '1课时',
          order: '20210905',
          student: '张三'
        },
        {
          classname: '高年级C++班',
          time: '2021年9月6日',
          name: '钢琴课',
          place: '星乐思教室',
          teacher: '张老师',
          xiaohao: '1课时',
          order: '20210906',
          student: '张三'
        },
        {
          classname: '高年级C++班',
          time: '2021年9月7日',
          name: '钢琴课',
          place: '星乐思教室',
          teacher: '张老师',
          xiaohao: '1课时',
          order: '20210907',
          student: '张三'
        }
      ]
    }
  }
}
</script>

<style scoped>
.app {
  background-image: linear-gradient(-225deg, #FFFEFF 0%, #D7FFFE 100%);
  height: 100%;
  background-attachment:fixed;
}
</style>